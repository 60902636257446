@charset "UTF-8";
@-ms-viewport {
	width: device-width;
}
@font-face {
	src: url('./fonts/Ubuntu-Bold.ttf');
	font-family: 'Ubuntu';
	font-style: normal;
	font-display: swap;
	font-weight: bold;
}
@font-face {
	src: url('./fonts/Ubuntu-Italic.ttf');
	font-family: 'Ubuntu';
	font-style: italic;
	font-display: swap;
	font-weight: 800;
}
@font-face {
	src: url('./fonts/Ubuntu-BoldItalic.ttf');
	font-family: 'Ubuntu';
	font-style: italic;
	font-display: swap;
	font-weight: bold;
}
@font-face {
	src: url('./fonts/Ubuntu-Medium.ttf');
	font-family: 'Ubuntu';
	font-style: normal;
	font-display: swap;
	font-weight: 500;
}
@font-face {
	src: url('./fonts/Ubuntu-MediumItalic.ttf');
	font-family: 'Ubuntu';
	font-style: italic;
	font-display: swap;
	font-weight: 500;
}
@font-face {
	src: url('./fonts/Ubuntu-Regular.ttf');
	font-family: 'Ubuntu';
	font-style: normal;
	font-display: swap;
	font-weight: 400;
}
@font-face {
	src: url('./fonts/Ubuntu-Light.ttf');
	font-family: 'Ubuntu';
	font-style: normal;
	font-display: swap;
	font-weight: 300;
}
@font-face {
	src: url('./fonts/Ubuntu-LightItalic.ttf');
	font-family: 'Ubuntu';
	font-style: italic;
	font-display: swap;
	font-weight: 300;
}
:root {
	--bold: #232b2e !important;
	--black: #1c1f26 !important;
	--brown: #6e7892 !important;
	--blue: #5254f1 !important;
	--yellow: #ffe984 !important;
	--light-brown: #f9f6f3 !important;
	--brown-light: rgba(110, 120, 146, 0.5) !important;
	--white: #ffffff !important;
	--red: #ff5749 !important;
	--green: #46cb5c !important;
	--toastify-color-info: #5254f1;
	--toastify-color-success: #46cb5c;
	--toastify-color-warning: #f1c40f;
	--toastify-color-error: #ff5749;
}
* {
	box-sizing: border-box;
}
html {
	scroll-behavior: smooth;
}
body {
	background: var(--white);
	overflow-y: scroll;
	font-weight: 400 !important;
	margin: 0;
	color: var(--bold);
}
body,
button,
select,
input,
textarea {
	font-family: 'Ubuntu', sans-serif !important;
}
::selection {
	background: var(--yellow);
	color: var(--black);
}
::-webkit-scrollbar {
	width: 7px;
	height: 7px;
}
::-webkit-scrollbar-track {
	background: var(--white);
}
::-webkit-scrollbar-thumb {
	background: var(--blue);
	border-radius: 3px;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	-ms-border-radius: 3px;
	-o-border-radius: 3px;
}
a {
	text-decoration: none !important;
	cursor: pointer;
}
ul {
	list-style-type: none !important;
	padding: 0;
}
input {
	outline: none !important;
	box-shadow: none !important;
	font-family: 'Ubuntu', sans-serif;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}
input[type='number'] {
	-moz-appearance: textfield;
}
input[type='date'] {
	cursor: text !important;
}
textarea {
	resize: none !important;
}
button {
	outline: none !important;
	box-shadow: none !important;
	cursor: pointer;
}
.container {
	width: 100% !important;
	box-sizing: border-box !important;
	padding: 0 calc(50% - 900px) !important;
}
input{
	-webkit-box-align: center;
    align-items: center;
    background-color: rgb(255, 255, 255);
    border-color: rgb(204, 204, 204);
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    cursor: default;
    display: flex;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    justify-content: space-between;
    min-height: 38px;
    position: relative;
    transition: all 100ms ease 0s;
    box-sizing: border-box;
    outline: 0px !important;
}
.container_target{
	padding: 0 10px;
}
.btn{
	align-items: center;
	border-radius: 4px;
	border-style: solid;
	border-width: 1px;
	cursor: pointer;
	display: flex;
	flex-wrap: wrap;
	min-height: 38px;
	position: relative;
	transition: all 100ms ease 0s;
	box-sizing: border-box;
	outline: 0px !important;
	text-align: center;
	background: var(--blue);
	color: #fff;
	border-color: var(--blue);
	justify-content: center;
	width: 190px;
}
.rodal .rodal-mask{
	background: rgba(0, 0, 0, 0.5);
}
.rodal .rodal-dialog{
	max-width: 95%;
	height: max-content !important;
	border-radius: 10px;
}
.rodal .rodal-dialog .rodal-head{
	font-size: 20px;
    text-align: center;
    margin-bottom: 10px;
}
.rodal .rodal-dialog .rodal-body{
	font-size: 16px;
    max-height: 80vh;
    overflow: auto;
}